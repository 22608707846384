<template>
  <v-flex>
    <div class="d-flex">
      <v-text-field
        class="mr-2"
        label="Situação"
        name="situacao"
        placeholder="Situação"
        v-model="situacao"
        :readonly="true"
      ></v-text-field>
      <v-text-field
        class="mr-2"
        label="Praça"
        name="praca"
        placeholder="Praça"
        v-model="praca"
        :readonly="true"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field class="mr-2" label="CPF" name="cpf" placeholder="CPF" v-model="cpf" :readonly="true"></v-text-field>
      <v-text-field
        class="mr-2"
        label="Nome"
        name="nome"
        placeholder="Nome"
        v-model="nome"
        :readonly="true"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field class="mr-2" label="RG" name="rg" placeholder="RG" v-model="rg" :readonly="true"></v-text-field>
      <v-text-field
        class="mr-2"
        label="Telefone"
        name="telefone"
        placeholder="Telefone"
        v-model="telefone1"
        :readonly="true"
      ></v-text-field>
      <v-text-field
        class="mr-2"
        label="Telefone"
        name="telefone2"
        placeholder="Telefone"
        v-model="telefone2"
        :readonly="true"
      ></v-text-field>
      <v-text-field
        class="mr-2"
        label="Data nascimento"
        name="dtNascimento"
        placeholder="Data nascimento"
        v-model="dtNascimento"
        :readonly="true"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field class="mr-2" label="Cep" name="cep" placeholder="Cep" v-model="cep" :readonly="true"></v-text-field>
      <v-text-field
        class="mr-2"
        label="Cidade"
        name="cidade"
        placeholder="Cidade"
        v-model="cidade"
        :readonly="true"
      ></v-text-field>
      <v-text-field
        class="mr-2"
        label="Bairro"
        name="bairro"
        placeholder="Bairro"
        v-model="bairro"
        :readonly="true"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field class="mr-2" label="Rua" name="rua" placeholder="Rua" v-model="rua" :readonly="true"></v-text-field>
      <v-text-field
        class="mr-2"
        label="Número"
        name="numero"
        placeholder="Número"
        v-model="numero"
        :readonly="true"
      ></v-text-field>
      <v-text-field
        class="mr-2"
        label="Estado"
        name="estado"
        placeholder="Estado"
        v-model="estado"
        :readonly="true"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-data-table :headers="headersVendedorPagamento" :items="vendedorPagamento" hide-actions class="elevation-0">
        <template v-slot:items="props">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.idNotaEntradaRetorno }}</td>
          <td>{{ props.item.totalVenda }}</td>
          <td>{{ props.item.percentualVendedora }}</td>
          <td>{{ props.item.valorASerPago }}</td>
          <td>{{ props.item.totalPago }}</td>
          <td>{{ props.item.saldo }}</td>
          <td>
            <v-icon small title="Detalhes" @click="detalhes(props.item.vendedorPagamentoItem)">remove_red_eye</v-icon>
          </td>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="headline">Detalhes Pagamento</v-card-title>
        <v-card-text>
          <v-data-table :headers="headerDetalhePagamento" :items="itensPagamento" class="elevation-0">
            <template v-slot:items="props">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.valorPago }}</td>
              <td>{{ props.item.dataPagamento }}</td>
              <td>{{ props.item.dataInclusao }}</td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark text @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="form-btn">
      <v-btn outline @click="voltar()">Voltar</v-btn>
    </div>
  </v-flex>
</template>
<script>
import { ServicoVendedor } from "../../servicos/servicoVendedor"
const servicoVendedor = new ServicoVendedor()
export default {
  data() {
    return {
      dialog: false,
      id: null,
      situacao: "",
      praca: "",
      cpf: "",
      nome: "",
      rg: "",
      telefone1: "",
      telefone2: "",
      dtNascimento: "",
      cep: "",
      cidade: "",
      bairro: "",
      rua: "",
      numero: "",
      estado: "",
      vendedorPagamento: [],
      itensPagamento: [],
      headersVendedorPagamento: [
        { text: "ID", align: "left", sortable: false, value: "id" },
        { text: "Retorno", align: "left", sortable: false, value: "idNotaEntradaRetorno" },
        { text: "Total Venda", align: "left", sortable: false, value: "totalVenda" },
        { text: "%", align: "left", sortable: false, value: "percentualVendedora" },
        { text: "Valor a ser Pago", align: "left", sortable: false, value: "valorASerPago" },
        { text: "Total Pago", align: "left", sortable: false, value: "totalPago" },
        { text: "Saldo", align: "left", sortable: false, value: "saldo" },
        { text: "Opções", align: "center", sortable: false, value: "" }
      ],
      headerDetalhePagamento: [
        { text: "ID", align: "left", sortable: false, value: "id" },
        { text: "Valor Pago", align: "left", sortable: false, value: "valorPago" },
        { text: "Data pagamento", align: "left", sortable: false, value: "dataPagamento" },
        { text: "Data inclusão", align: "left", sortable: false, value: "dataInclusao" }
      ]
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoVendedor.buscarPorId(this.id).then(res => {
        this.id = res.data.id
        this.situacao = res.data.situacao
        this.praca = res.data.praca
        this.cpf = res.data.cpf
        this.nome = res.data.nome
        this.rg = res.data.rg
        this.telefone1 = res.data.telefone1
        this.telefone2 = res.data.telefone2
        this.dtNascimento = res.data.dtNascimento
        this.cep = res.data.cep
        this.cidade = res.data.cidade
        this.bairro = res.data.bairro
        this.rua = res.data.rua
        this.numero = res.data.numero
        this.estado = res.data.estado
        this.vendedorPagamento = res.data.vendedorPagamento
      })
    }
  },
  methods: {
    voltar() {
      this.$router.push({ name: "Vendedores" }) 
    },
    detalhes(vendedorPagamentoItens) {
      this.itensPagamento = vendedorPagamentoItens
      this.dialog = true
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
