import axios from "axios"

export class ServicoVendedor {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Vendedor`
  }
  getTableVendedores() {
    return axios({
      url: this.urlServico + `/GetTableVendedores`,
      method: "GET"
    })
  }
  buscarPorIdPraca(idPraca) {
    return axios({
      url: this.urlServico + `/GetVendedorSelect?idPraca=${idPraca}`,
      method: "GET"
    })
  }
  validarCPFjaExiste(cpf) {
    return axios({
      url: this.urlServico + `/ValidarCPFnaoCadastrado?cpf=${cpf}`,
      method: "GET"
    })
  }
  salvarNovoVendedor(vendedor) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/SalvarNovoVendedor", vendedor, config).catch(error => {
      alert("Erro ao salvar um novo vendedor " + error)
      return Promise.reject(error)
    })
  }
  editarVendedor(vendedor) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/EditarVendedor", vendedor, config).catch(error => {
      alert("Erro ao editar o vendedor " + error)
      return Promise.reject(error)
    })
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
  unificar(idPermanecer, idExcluir) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/UnificarVendedor", { idPermanecer, idExcluir }, config).catch(error => {
      alert("Erro ao unificar o vendedor " + error)
      return Promise.reject(error)
    })
  }
}
